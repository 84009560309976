/* You can add global styles to this file, and also import other style files */

@import '~perfect-scrollbar/css/perfect-scrollbar.css';
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

:root {
  --dark-gray: #242a33;
  --dark-gray-rgb: 36, 42, 51;
  --alpha: 0.9;
  --light-gray: #455a64;
  --lighter-gray: #D8D8D8;
  --white: #ffffff;
  --red: #e30613;
}

.red {
  color: var(--red);
}

.ps .ps__scrollbar-y-rail {
  left: 3px;
  right: auto;
}

a {
  text-decoration: none !important;
}
a.underline {
  text-decoration: underline !important;
}

.dark-logo {
  max-width: 150px;
  padding: 10px 15px;
}

a {
  cursor: pointer;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.hide {
  display: none;
}
.tooltip-info {
  padding: 10px;
}
.tooltip-wide .tooltip-inner{
  width: 350px;
  white-space: pre-wrap;
}
.image-gallery {
  width: 100%;
  position: relative;
  margin-left: -20px;
}
.thumbnail {
  padding: 5px;
  float: left;
  position: relative;
}
.thumbnail img {
  object-fit: cover;
  width: 100%;
  max-width: 350px;
  max-height: 350px;
  border: 1px solid lightgray;
}
img.flag {
  max-height: 30px;
  object-fit: cover;
}
.radio_group_column {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
}
.radio_group_column label {
  font-weight: 300 !important;
}
.mat-cell, .mat-footer-cell, .mat-header-cell {
  word-break: break-word;
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,0.2);
  border-radius: .3rem;
  outline: 0;
  height: 600px;
}
mat-vertical-stepper .mat-list-item-content {
  margin-top:10px !important;
}
.w-full {
  width: 100%;
}

.stats .value {
  text-align:center;
  font-weight: 600;
  padding: 10px 0;
  margin: 0 auto;
  display: block;
  font-size: 26px;
}

.lstick {
  margin-left: 0px !important;
}

.input-with-button { display: table; }
.input-with-button .input {
    display: table-cell;
    width: 100%;
}
.input-with-button .input > input {
    width: 100%;
}


@media (max-width: 767px) {
  .topbar .top-navbar .navbar-header {
    width: 0px !important;
  }

  /* .dark-logo */
}
@media (min-width: 768px) {
  .topbar .top-navbar .navbar-header {
    width: 0px !important;
  }
  .defaultdark .topbar .top-navbar .navbar-header .navbar-brand {
    margin-left: 50px;
  }

  .md-drppicker.double.show-ranges {
    min-width: 750px;
   }

   .md-drppicker td.active, .md-drppicker td.available.in-range.active,  button.active, .md-drppicker td.active:hover, .md-drppicker .btn {
       background: #e30613 !important;
   }


   .md-drppicker td.off, .md-drppicker td.off.end-date, .md-drppicker td.off.in-range, .md-drppicker td.off.start-date {
     background-color: #fff !important;
   }

   .md-drppicker .btn {
       padding: 0 10px !important;
       border-radius: 10px !important;
   }
   .stats span {
    font-size: 1.9vw;
  }
}
mat-hint {
  font-weight: 600;
}
@media (max-width: 1007px) {
  mat-table {
    display: table !important;
  }
  .mat-mdc-cell, .mat-mdc-header-cell, .mat-mdc-footer-cell {
    justify-content: center;
    text-align: center;
    flex-direction: column;
    min-width: 150px;
  }
  .input-with-button .input {
    display: block;
  }
   .mat-mdc-footer-cell, .mat-mdc-header-cell {
    word-break: normal !important;
  }
  .mat-mdc-cell {
    word-break: normal !important;
  }
}
.dark {
  background-color: #242a33 !important;
  color: #fff;
}
.dark .card-body > * {
  color: #fff;
}

/* .stats button {
  float: right; margin-top:-30px;
} */
.stats button mat-icon {
  margin-top:-3px;
}

.stats-table {
  border-collapse: collapse;
  width: 100%;
  max-width: 800px;
  margin-bottom: 40px;
}

.stats-table td, .stats-table th {
  border: 1px solid #ddd;
  padding: 8px;
}



.stats-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
}
.mat-button-toggle-checked {
  background-color: #242a33 !important;
  color: #fff !important;
}
.legacy-buttons .mat-button-toggle {
  color: black;
  border: 1px solid lightgray;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.legacy-buttons .mat-button-toggle-checked {
  background-color: #242a33;
  color: #fff;
  border: 1px solid #242a33;
}
.legacy-buttons .mat-button-toggle-group {
  box-shadow: none;
  background: #fff;
}
.filter.input-select .mat-mdc-form-field-flex {
  padding: 0em 5px 0px 5px !important;
  background-color: #f5f6fc !important;
  top: 2px;
}

.finance-module .card {
  width: 100%;
}
.finance-module .col-md-3, .finance-module .col-md-4 {
  display: flex;
}

.select-height-as-input-height .mat-select-trigger {
  height: 2.325em;
}

.navbar-nav .mat-slide-toggle-content {
  font-family: inherit;
}

.full-width {
  width: 100%;
}
textarea {
  border: 1px solid #ccc;
  color: #555;
  padding: 6px 12px;
  font-size: 14px;
}

.mat-mdc-table .mdc-data-table__row {
  height: auto !important;
}

.filterable-table .mat-mdc-cell:first-of-type, .filterable-table .mat-mdc-header-cell:first-of-type, .filterable-table .mat-mdc-footer-cell:first-of-type {
  padding-left: 16px !important;
}

/** TO DO - ZASTĄPIENIE MAT-CHIP NOWYM MODUŁEM **/

.images-container .mdc-line-ripple::after, .images-container .mdc-line-ripple::before {
  display: none;
}

.mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0,0,0,.87)
}

.mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0,0,0,.87);
  opacity: .4
}

.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0 3px 3px -2px rgba(0,0,0,.2),0 3px 4px 0 rgba(0,0,0,.14),0 1px 8px 0 rgba(0,0,0,.12)
}

.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: .54
}

.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: .4
}

.mat-chip.mat-standard-chip:after {
  background: #000
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #607d8b;
  color: #fff
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: #fff;
  opacity: .4
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: hsla(0,0%,100%,.1)
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: #fff
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: #fff;
  opacity: .4
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: hsla(0,0%,100%,.1)
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #607d8b;
  color: #fff
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: #fff;
  opacity: .4
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: hsla(0,0%,100%,.1)
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
}

/** TO DO **/

.float-right {
  float: right !important;
}
.float-left {
  float: left !important;
}

.mdc-text-field--disabled.mdc-text-field--filled {
  background-color: transparent !important;
}

.filterable-table .mat-mdc-header-cell h6 {
  margin-top: 20px;
  padding-bottom: 10px;
  border-bottom: 2px solid rgba(49,157,181,.1);
  width: 100%;
  height: 40px;
  word-break: break-word;
  margin-bottom: 0px !important;
}

.filterable-table .mat-mdc-header-cell {
  flex-direction: column;
}
.filterable-table .mat-mdc-input-element {
  background-color: #f5f6fc!important;
  padding: 0 5px!important;
}
.filterable-table .filter .mat-mdc-form-field-bottom-align {
  display: none;
}
.filterable-table .filter {
  padding: 6px 0;
}
.filterable-table .mat-mdc-form-field-infix {
  padding: 0px !important;
  min-height: 40px !important;
}
.filterable-table .mdc-text-field {
  padding: 0px !important;
}
.filterable-table .mat-mdc-input-element  {
  height: 40px !important;
}
.mat-mdc-input-element {
  margin-bottom: 0px !important
}
.filterable-table .mat-mdc-header-cell .mat-mdc-select {
  padding-top: 9px !important;
}

.filterable-table .mdc-data-table__cell, .filterable-table .mdc-data-table__header-cell {
  padding: 0 6px !important;
}

.filterable-table .mat-mdc-cell, .mat-mdc-footer-cell, .mat-mdc-header-cell {
  padding: 0 5px;
}

.filterable-table .mat-mdc-header-row {
  align-items: flex-start;
}
/* .filterable-table input.mat-input-element {
  margin-top: -1.7em;
} */
.filterable-table .mat-mdc-form-field {
  display: inline-block;
  position: relative;
  text-align: left;
  width: 100%;
}

.table-head {
  border-bottom: 1px solid lightgray;
  padding-bottom: 10px;
}
.table-head .col-md-3 {
  font-weight: 600;
}
.table-row .icon {
    margin:10px;
}
.table-row:first-child {
  padding-top: 10px;
}
.table-row input {
  height: 26px;
}

/** Mechanizm wyboru kolorów **/
.color-option {
  display: flex;
  align-items: center;
}

.color-box {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}
/** -- Mechanizm wyboru kolorów **/


/** Powiadomienia **/

.notification-menu-trigger {
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
}
.menu-notifications {
  max-width: 330px !important;
}

.menu-notification-item > * {
  width: 100% !important;
}
/** -- Powiadomienia **/

strong {
  font-family: "Montserrat", sans-serif !important;
}

.mat-mdc-dialog-actions {
  justify-content: end !important;
}

/** Statusy klienta **/
.status-option {
  display: flex;
  align-items: center;
}

.color-box {
  width: 16px;  /* Szerokość boxa */
  height: 16px; /* Wysokość boxa */
  margin-right: 8px; /* Odstęp między boxem a tekstem */
  border-radius: 50%; /* Zaokrąglony kształt */
}
/** -- Statusy klienta **/